import {
	ActivityFn,
	RegisterApplicationConfig,
	addErrorHandler,
	getAppStatus,
	navigateToUrl,
	registerApplication,
	start,
} from 'single-spa';
import { loadEmberApp } from 'single-spa-ember';
import { WithLoadFunction, constructApplications, constructLayoutEngine, constructRoutes } from 'single-spa-layout';
import PubSub from 'pubsub-js';
import { loadEmberUrls } from './loadEmberUrls';
import '../assets/scss/layout.scss';
import microfrontendLayout from './microfrontend-layout.html';
import { checkIsMFsIsAvailable } from './utils';
import { loadApp } from './asset-loader';

const publish = (event, data) => PubSub.publish(event, data);
const subscribe = (event, callback) => PubSub.subscribe(event, callback);
const unsubscribe = (event) => PubSub.unsubscribe(event);
type SpaApplications = (RegisterApplicationConfig & WithLoadFunction)[];
let applications: (RegisterApplicationConfig & WithLoadFunction)[] = [];
let skippedApplications: string[] = [];

const mountApplications = async (applications: SpaApplications) => {
	const { appUrl, vendorUrl } = await loadEmberUrls();
	const canLoadApplications = await checkIsMFsIsAvailable();

	applications.forEach((app) => {
		if (app.name === '@shift4/lh4') {
			// existing ember app has special app registration because it is hosted from '/'
			// it is rendered only when there are no other MFs active at current location
			registerApplication(
				'@shift4/lh4',
				() => {
					const appName = 'lh4';
					return loadEmberApp(appName, appUrl, vendorUrl);
				},
				(location) =>
					location.pathname === '/' ||
					!applications.find(
						(a) =>
							a.name !== '@shift4/lh4' &&
							a.name !== '@shift4/frontend-navigation' &&
							(a.activeWhen as []).find((i) => (i as ActivityFn)(location)),
					),
				{ publish, subscribe, unsubscribe },
			);
		} else {
			if (!canLoadApplications) {
				skippedApplications.push(app.name);
				console.warn(`Cannot load application ${app.name} for this user.`);
				return;
			}
			// all other apps are registered as usual
			registerApplication({
				...app,
				customProps: () => ({
					publish,
					subscribe,
					unsubscribe,
				}),
			});
		}
	});
};

void (async () => {
	subscribe('IS_OPEN', (_msg, isSidebarOpen) => {
		const appShell = document.getElementById('shift4-app-shell');
		const navigation = document.getElementById('shift4-navigation');

		if (appShell && navigation) {
			appShell.classList.add(isSidebarOpen ? 'sidebar-content' : 'full-width-content');
			appShell.classList.remove(isSidebarOpen ? 'full-width-content' : 'sidebar-content');

			navigation.style.opacity = '1';

			// TODO: this exists to support conditional rendering between
			// the React nav and the Ember Nav. Once we have fully
			// migrated we can remove references to 'isFrontendNavigationMounted' and 'display-grid' class
			// in layout.scss and set #shift4-app-shell display property value back to 'grid'.
			const observer = new MutationObserver(() => {
				const isFrontendNavigationMounted = navigation?.querySelector('header');

				if (isFrontendNavigationMounted) {
					appShell.classList.add('display-grid');
					observer.disconnect();
				}
			});

			observer.observe(navigation, { childList: true, subtree: true });
		}
	});

	subscribe('SIGNOUT', () => {
		const appShell = document.getElementById('shift4-app-shell');
		const navigation = document.getElementById('shift4-navigation');

		if (appShell && navigation) {
			appShell.classList.remove('full-width-content');
			appShell.classList.remove('sidebar-content');

			navigation.style.opacity = '0';
		}
	});

	subscribe('AUTH', () => {
		const appsToMount = applications.filter((app) => {
			return skippedApplications.includes(app.name);
		});
		skippedApplications = [];
		void mountApplications(appsToMount);
	});

	addErrorHandler((err) => {
		console.error('Failed to load the App');
		console.error(`App name: ${err.appOrParcelName}`);
		console.error(`App status: ${getAppStatus(err.appOrParcelName)}`);
		console.error(`Error message: ${err.message}`);
	});

	const routes = constructRoutes(microfrontendLayout);
	applications = constructApplications({
		routes,
		loadApp,
	});

	const layoutEngine = constructLayoutEngine({ routes, applications });
	await mountApplications(applications);

	const isEmberAppActive =
		applications.find((app) => app.name === '@shift4/lh4')?.activeWhen[0](window.location) ?? false;

	if (!isEmberAppActive && skippedApplications.length > 0) {
		// When FF check is enabled and React applications are not mounted so we need to force user sign in.
		navigateToUrl('/sign-in');
	}
	layoutEngine.activate();
	start();
})();
